import Chart from 'chart.js/auto'
import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['userid', 'userchoices', 'daterange', 'timelist', 'hoursgraph', 'ticketlist', 'ticketgraph', 'todolist', 'todograph', 'latetodolist']

  initialize() {
    this.userchoicesTarget.querySelectorAll('option').forEach(_option => {
    })
  }

  greet() {
    if (this.userchoicesTarget.querySelectorAll('option').length > 0 && this.daterangeTarget.value) {
      this.getTimeList(this.user_id)
      this.getHoursGraph(this.user_id)
      this.getTicketList(this.user_id)
      this.getTicketGraph(this.user_id)
      this.getTodoList(this.user_id)
      this.getTodoGraph(this.user_id)
      this.getLateList(this.user_id)
    }
  }

  get user_id() {
    // return the value of all options in the userchoicesTarget
    return this.useridTarget.value
  }

  get from() {
    // split the date range into two parts
    const dates = this.daterangeTarget.value.split('-')
    return dates[0]
  }

  get to() {
    const dates = this.daterangeTarget.value.split('-')
    return dates[1]
  }

  // get todolist() {
  //   return this.todolistTarget
  // }

  get timelist() {
    return this.timelistTarget
  }

  // create a function to make a request to the server to get the todo list for a user
  getTodoList(userid) {
    // fetch the todo list for the user
    this.todolistTarget.innerHTML = ''
    fetch(`/users/${userid.slice(5)}/get_todo_list?from=${this.from}&to=${this.to}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        // clear the todo list
        // this.todolistTarget.innerHTML = ''
        // loop through the todo list and add each item to the todo list table as a row
        data.forEach(todo => {
          const row = document.createElement('tr')
          const td1 = document.createElement('td')
          const td2 = document.createElement('td')
          const td3 = document.createElement('td')
          const td4 = document.createElement('td')
          const td5 = document.createElement('td')
          const td6 = document.createElement('td')
          const td7 = document.createElement('td')
          const td8 = document.createElement('td')
          const td9 = document.createElement('td')
          const td10 = document.createElement('td')
          const td11 = document.createElement('td')

          td1.innerHTML = `<a href="/reports/${todo.rep_id}">${todo.ref_number}</a>`
          td2.innerHTML = todo.title
          td3.innerHTML = todo.status
          td4.innerHTML = todo.created_at
          td5.innerHTML = todo.created_by
          td6.innerHTML = todo.ended_at
          td7.innerHTML = todo.ended_by
          td8.innerHTML = todo.closed_at
          td9.innerHTML = todo.closed_by
          td10.innerHTML = todo.unit
          td11.innerHTML = todo.amenity

          row.appendChild(td1)
          row.appendChild(td2)
          row.appendChild(td3)
          row.appendChild(td4)
          row.appendChild(td5)
          row.appendChild(td6)
          row.appendChild(td7)
          row.appendChild(td8)
          row.appendChild(td9)
          row.appendChild(td10)
          row.appendChild(td11)

          this.todolistTarget.appendChild(row)
        })
      })
  }
  getTodoGraph(userid) {
    this.todographTarget.innerHTML = ''
    fetch(`/users/${userid.slice(5)}/get_todo_graph?from=${this.from}&to=${this.to}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        // clear the todo list
        let canvas = document.createElement('canvas')
        canvas.classList.add('chart')
        this.todographTarget.appendChild(canvas)
        new Chart(canvas, {
          type:    'bar',
          data:    data,
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        })
      })
  }

  // fetch the todo list for the user
  getTimeList(userid) {
    // Do not fetch if hours are not counted
    if (!this.hasTimelistTarget) return

    // Fetch hours
    this.timelistTarget.innerHTML = ''
    fetch(`/users/${userid.slice(5)}/get_hours_list?from=${this.from}&to=${this.to}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        // clear the todo list
        // loop through the todo list and add each item to the todo list table as a row
        data.forEach(line => {
          let row = document.createElement('tr')
          let td1 = document.createElement('td')
          let td2 = document.createElement('td')
          let td3 = document.createElement('td')
          let td4 = document.createElement('td')
          let td5 = document.createElement('td')

          td1.innerHTML = line.date
          td2.innerHTML = `<a href="/reports/${line.rep_id}">${line.ref_number}</a>`
          td3.innerHTML = line.title
          td4.innerHTML = `${String(Math.floor(line.quantity / 3600)).padStart(2, '0')}:${String(Math.floor(line.quantity % 3600 / 60)).padStart(2, '0')}`
          td5.innerHTML = `${line.total_price} ${line.currency}`

          row.appendChild(td1)
          row.appendChild(td2)
          row.appendChild(td3)
          row.appendChild(td4)
          row.appendChild(td5)

          this.timelistTarget.appendChild(row)
        })
      })
  }

  getHoursGraph(userid) {
    // Do not fetch if hours are not counted
    if (!this.hasHoursgraphTarget) return

    // fetch the todo list for the user
    this.hoursgraphTarget.innerHTML = ''
    fetch(`/users/${userid.slice(5)}/get_hours_graph?from=${this.from}&to=${this.to}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        // clear the todo list
        let canvas = document.createElement('canvas')
        canvas.classList.add('chart')
        this.hoursgraphTarget.appendChild(canvas)
        new Chart(canvas, {
          type:    'bar',
          data:    data,
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        })
      })
  }

  getTicketList(userid) {
    // fetch the todo list for the user
    this.todolistTarget.innerHTML = ''
    fetch(`/users/${userid.slice(5)}/get_new_ticket_list?from=${this.from}&to=${this.to}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        // clear the todo list
        // loop through the todo list and add each item to the todo list table as a row
        data.forEach(todo => {
          const row = document.createElement('tr')
          const td1 = document.createElement('td')
          const td2 = document.createElement('td')
          const td3 = document.createElement('td')
          const td4 = document.createElement('td')
          const td5 = document.createElement('td')
          const td6 = document.createElement('td')

          td1.innerHTML = todo.created_at
          td2.innerHTML = `<a href="/reports/${todo.id}">${todo.report_number}</a>`
          td3.innerHTML = todo.title
          td4.innerHTML = todo.status
          td5.innerHTML = todo.unit
          td6.innerHTML = todo.amenity

          row.appendChild(td1)
          row.appendChild(td2)
          row.appendChild(td3)
          row.appendChild(td4)
          row.appendChild(td5)
          row.appendChild(td6)

          this.ticketlistTarget.appendChild(row)
        })
      })
  }

  getTicketGraph(userid) {
    this.ticketgraphTarget.innerHTML = ''
    fetch(`/users/${userid.slice(5)}/get_new_ticket_graph?from=${this.from}&to=${this.to}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        // clear the todo list
        let canvas = document.createElement('canvas')
        canvas.classList.add('chart')
        this.ticketgraphTarget.appendChild(canvas)
        new Chart(canvas, {
          type:    'bar',
          data:    data,
          options: {
            scales: {
              y: {
                beginAtZero: true
              }
            }
          }
        })
      })
  }

  getLateList(userid) {
    this.latetodolistTarget.innerHTML = ''
    fetch(`/users/${userid.slice(5)}/get_late_todo_list?from=${this.from}&to=${this.to}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        data.forEach(todo => {
          const row = document.createElement('tr')
          const td1 = document.createElement('td')
          const td2 = document.createElement('td')
          const td4 = document.createElement('td')
          const td5 = document.createElement('td')
          const td6 = document.createElement('td')

          const td10 = document.createElement('td')
          const td11 = document.createElement('td')

          td1.innerHTML = `<a href="/reports/${todo.rep_id}">${todo.ref_number}</a>`
          td2.innerHTML = todo.title
          td4.innerHTML = todo.created_at
          td5.innerHTML = todo.created_by
          var warning = ''
          if (todo.late == 'true') { warning = '<i class="fas fa-exclamation-triangle text-danger"> </i>' }
          td6.innerHTML = `${warning} ${todo.scheduled_at}`
          td10.innerHTML = todo.unit
          td11.innerHTML = todo.amenity

          row.appendChild(td1)
          row.appendChild(td2)
          row.appendChild(td4)
          row.appendChild(td5)
          row.appendChild(td6)

          row.appendChild(td10)
          row.appendChild(td11)

          this.latetodolistTarget.appendChild(row)
        })
      })
  }
}
