import Choices from 'choices.js'
import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  connect() {
    if (this.element.querySelector('.js-choice')) {
      this.element.querySelectorAll('.js-choice').forEach((selector) => {
        new Choices(selector, {
          resetScrollPosition: false,
          removeItemButton:    true,
          itemSelectText:      '',
          allowHTML:           true,
          searchResultLimit:   10
        })
      })
    }

    if (this.element.querySelector('.js-choice-original-order')) {
      this.element.querySelectorAll('.js-choice-original-order').forEach((selector) => {
        new Choices(selector, {
          resetScrollPosition: false,
          removeItemButton:    true,
          allowHTML:           true,
          itemSelectText:      ' ',
          shouldSort:          false,
          searchResultLimit:   10
        })
      })
    }

    if (this.element.querySelector('.js-choice-text')) {
      this.element.querySelectorAll('.js-choice-text').forEach((input) => {
        new Choices(input, {
          maxItemCount:          1,
          duplicateItemsAllowed: false,
          allowHTML:             true,
          addItemText:           'Press Enter to add'
        })
      })
    }

  }
}
