import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'selectTypeBtn',
    'createTypeBtn',
    'typeSelection',
    'typeCreation',
    'typeSelectionInput',
    'typeCreationInput',
    'receivedPrice',
    'checkBox',
    'conversion'
  ]

  connect() {
    if (this.typeSelectionInputTarget.value !== '') { this.typeSelectionTarget.classList.remove('hidden') }
    this.useAsMaterialPrice()
    this.checkSubmit()
  }

  get submitBtn() {
    return document.getElementById('submit-button')
  }

  selectExisting() {
    this.typeCreationInputTarget.value = ''
    this.typeCreationTarget.classList.add('hidden')
    this.createTypeBtnTarget.classList.add('btn-outline-primary')
    this.createTypeBtnTarget.classList.remove('btn-primary')

    this.typeSelectionInputTarget.value = ''
    this.typeSelectionTarget.classList.add('hidden')
    this.selectTypeBtnTarget.classList.add('btn-outline-primary')
    this.selectTypeBtnTarget.classList.remove('btn-primary')

    this.checkSubmit()
  }

  revealTypeSelection() {
    this.element.querySelectorAll('.radio_button').forEach((radio) => {
      radio.checked = false
    })

    this.typeCreationInputTarget.value = ''
    this.typeSelectionTarget.classList.remove('hidden')
    this.selectTypeBtnTarget.classList.remove('btn-outline-primary')
    this.selectTypeBtnTarget.classList.add('btn-primary')

    this.typeCreationTarget.classList.add('hidden')
    this.createTypeBtnTarget.classList.add('btn-outline-primary')
    this.createTypeBtnTarget.classList.remove('btn-primary')

    this.checkSubmit()
  }

  revealTypeCreation() {
    this.element.querySelectorAll('.radio_button').forEach((radio) => {
      radio.checked = false
    })

    this.typeSelectionInputTarget.value = ''
    this.typeSelectionTarget.classList.add('hidden')
    this.selectTypeBtnTarget.classList.add('btn-outline-primary')
    this.selectTypeBtnTarget.classList.remove('btn-primary')

    this.typeCreationTarget.classList.remove('hidden')
    this.createTypeBtnTarget.classList.remove('btn-outline-primary')
    this.createTypeBtnTarget.classList.add('btn-primary')

    this.checkSubmit()
  }

  checkSubmit() {
    const formSubmitable    = this.checkTypeValuePresence()
    this.submitBtn.disabled = !formSubmitable
  }

  checkTypeValuePresence() {
    var check = false
    if (this.typeSelectionInputTarget.value !== '') { check = true }
    if (this.typeCreationInputTarget.value !== '')  { check = true }
    this.element.querySelectorAll('.radio_button').forEach((radio) => {
      if (radio.checked) { check = true }
    })
    return check
  }

  useAsMaterialPrice() {
    if (this.supplierCurrency === this.companyCurrency) { return }
    if (this.checkBoxTarget.checked) {
      this.updateDisplay()
    } else {
      this.conversionTarget.innerText = ''
    }
  }

  updateDisplay() {
    const valueToConvert = Number.parseFloat(this.receivedPriceTarget.value.replace(',', '.')).toFixed(2)
    fetch(`/exchange_rates/search?currency=${this.supplierCurrency}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF_Token': this.csrfToken
        }
      })
      .then(response => response.json())
      .then(data => {
        const exchange_rate = data.exchange_rate
        let displayText = exchange_rate ? `: ${valueToConvert} ${this.supplierCurrency} <i class="fa-solid fa-arrow-right"></i> ${(valueToConvert * exchange_rate).toFixed(2)} ${this.companyCurrency}`
          : `${this.missingErrorMessage}`
        this.conversionTarget.innerHTML = displayText
      })
  }

  get missingErrorMessage() {
    return `
      : <i class="fa-solid fa-triangle-exclamation text-danger"></i> ${i18n.t('exchange_rate.error.error_while_converting')}
    `
  }

  get supplierCurrency() {
    return this.checkBoxTarget.dataset.supplierCurrency
  }

  get companyCurrency() {
    return this.checkBoxTarget.dataset.companyCurrency
  }

  get csrfToken() {
    return document.head.querySelector('meta[name="csrf-token"]').getAttribute('content')
  }
}
