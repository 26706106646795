import Chart from 'chart.js/auto'

import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['daterange', 'amenityType','reportType', 'unit', 'amenity', 'expertise', 'user', 'company_selector', 'anomaly']

  connect() {
    this.showGraphs()
    this.populate_tables()
  }

  fetchData() {
    this.showGraphs()
    this.populate_tables()
  }

  showGraphs() {
    this.element.querySelectorAll('.stat_graph').forEach(graph => {
      switch (graph.dataset.graph) {
        case 'repartition':
          this.doughnut(graph)
          break
        case 'tickets':
          this.line(graph)
          break
        case 'cumulative':
          this.line(graph)
          break
        case 'responsible':
          this.line(graph)
          break
        case 'cumulative_cost_per_cost_type':
          this.cumulative_line(graph)
          break
        case 'dashboard':
          this.dashboard(graph)
          break

        case 'cumulative_cost_per_report_type':
          this.cumulative_line(graph)
          break
        case 'cumulative_hours_per_report_type':
          this.cumulative_line(graph)
          break
        case 'pie_hours_per_report_type':
          this.doughnut(graph)
          break
        case 'pie_cost_per_cost_type':
          this.doughnut(graph)
          break
        case 'pie_cost_per_report_type':
          this.doughnut(graph)
          break
        case 'monthly_tickets_stats':
          this.line(graph)
          break

        case 'stop_time_ratios':
          this.stacked_bar(graph)
          break
        case 'stop_times':
          this.stacked_bar(graph)
          break
        case 'status_pie':
          this.status_pie(graph)
          break

        case 'todos_status_pie':
          this.status_pie(graph)
          break

        // ADMIN CHARTS
        case 'admin_monthly_new_tickets':
          this.admin_bar(graph)
          break
        case 'admin_cumulative_new_tickets':
          this.admin_line(graph)
          break
        case 'admin_monthly_closed_maintenances':
          this.admin_bar(graph)
          break
        case 'admin_cumulative_closed_maintenances':
          this.admin_line(graph)
          break

        default:
      }
    })
  }

  get from() {
    if (this.daterangeTarget.value) {
      const dates = this.daterangeTarget.value.split('-')
      return dates[0]
    } else {
      return new Date((new Date()).setMonth((new Date()).getMonth()-1))
    }
  }

  get to() {
    if (this.daterangeTarget.value) {
      const dates = this.daterangeTarget.value.split('-')
      return dates[1]
    } else {
      return new Date()
    }
  }

  get expertise_id() {
    return this.expertiseTarget.value
  }

  get report_type_id() {
    return this.reportTypeTarget.value
  }

  get amenity_type_id() {
    return this.amenityTypeTarget.value
  }

  get amenity_id() {
    return this.amenityTarget.value
  }

  get unit_id() {
    return this.unitTarget.value
  }

  get anomaly_id() {
    return this.anomalyTarget.value || ''
  }

  get company_id() {
    if (document.getElementById('company_id')) {
      return document.getElementById('company_id').value
    } else {
      return ''
    }
  }

  get dateRangeParams() {
    return `from=${this.from}&to=${this.to}`
  }

  get allParams() {
    return `company_id=${this.company_id}&${this.dateRangeParams}&unit_id=${this.unit_id}&amenity_id=${this.amenity_id}&amenity_type_id=${this.amenity_type_id}&report_type_id=${this.report_type_id}&expertise_id=${this.expertise_id}&anomaly_id=${this.anomaly_id}`
  }

  line(elem) {
    fetch(`/companies/stat_graph_data?graph=${elem.dataset.graph}&${this.allParams}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        // clear previous chart
        elem.innerHTML = ''
        let canvas = document.createElement('canvas')
        canvas.classList.add('chart')
        elem.appendChild(canvas)
        new Chart(canvas, {
          type:    'line',
          data:    data,
          options: {
            maintainAspectRatio: false,
            scales:              {
              y: {
                beginAtZero: true
              }
            }
          }
        })
      })
  }

  dashboard(elem) {
    fetch(`/companies/stat_graph_data?graph=tickets&company_id=${this.company_id}&${this.dateRangeParams}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        // clear previous chart
        elem.innerHTML = ''
        let canvas = document.createElement('canvas')
        canvas.classList.add('chart')
        elem.appendChild(canvas)
        new Chart(canvas, {
          data:    data,
          options: {
            maintainAspectRatio: true,
            scales:              {
              y: {
                grid:        { display: false },
                stacked:     true,
                beginAtZero: true
              },
              x: {
                grid: { display: false }
              }
            }
          }
        })
      })
  }

  doughnut(elem) {
    fetch(`/companies/stat_graph_data?graph=${elem.dataset.graph}&${this.allParams}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        // clear previous chart
        elem.innerHTML = ''
        let canvas = document.createElement('canvas')
        canvas.classList.add('chart')
        elem.appendChild(canvas)
        new Chart(canvas, {
          type:    'doughnut',
          data:    data,
          options: {
            layout: {
              padding: 20
            },
            maintainAspectRatio: false,
            'plugins':           {
              'legend': {
                'display':  true,
                'position': 'right',
                'align':    'start'
              }
            },
            legend: {
              position: 'bottom',
              labels:   {
                boxWidth:      8,
                fontFamily:    'futura',
                usePointStyle: true
              }
            },
          }
        })
      })
  }

  cumulative_line(elem) {
    fetch(`/companies/stat_graph_data?graph=${elem.dataset.graph}&${this.allParams}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        // clear previous chart
        elem.innerHTML = ''
        let canvas = document.createElement('canvas')
        canvas.classList.add('chart')
        elem.appendChild(canvas)
        new Chart(canvas, {
          type:    'line',
          data:    data,
          options: {
            maintainAspectRatio: false,
            scales:              {
              y: {
                beginAtZero: true
              }
            }
          }
        })
      })
  }

  admin_line(elem) {
    fetch(`/admin/statistics/graph_data?graph=${elem.dataset.graph}&${this.dateRangeParams}&company_id=${this.company_selectorTarget.value}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        // clear previous chart
        elem.innerHTML = ''
        let canvas = document.createElement('canvas')
        canvas.classList.add('chart')
        elem.appendChild(canvas)
        new Chart(canvas, {
          type:    'line',
          data:    data,
          options: {
            maintainAspectRatio: false,
            scales:              {
              y: {
                beginAtZero: true
              }
            }
          }
        })
      }
      )
  }

  admin_bar(elem) {
    fetch(`/admin/statistics/graph_data?graph=${elem.dataset.graph}&${this.dateRangeParams}&company_id=${this.company_selectorTarget.value}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        // clear previous chart
        elem.innerHTML = ''
        let canvas = document.createElement('canvas')
        canvas.classList.add('chart')
        elem.appendChild(canvas)
        new Chart(canvas, {
          type:    'bar',
          data:    data,
          options: {
            maintainAspectRatio: false,
            scales:              {
              y: {
                beginAtZero: true
              }
            }
          }
        })
      }
      )
  }

  stacked_bar(elem) {
    fetch(`/companies/stat_graph_data?graph=${elem.dataset.graph}&${this.allParams}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        // clear previous chart
        elem.innerHTML = ''
        let canvas = document.createElement('canvas')
        canvas.classList.add('chart')
        elem.appendChild(canvas)
        new Chart(canvas, {
          type:    'bar',
          data:    data,
          options: {
            maintainAspectRatio: false,
            scales:              {
              x: {
                stacked: true
              },
              y: {
                stacked: true
              }
            }
          }
        })
      })
  }

  populate_tables() {

    this.element.querySelectorAll('.stat_table').forEach(table => {
      switch (table.dataset.table) {
        case 'top_amenities_with_most_stops':
          this.stops_table(table)
          break
        case 'top_amenities_with_longest_downtime':
          this.stops_table(table)
          break
        default:
      }
    })
  }

  stops_table(elem) {
    fetch(`/companies/stat_table_data?table=${elem.dataset.table}&${this.allParams}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        elem.innerHTML = ''
        data.forEach(line => {
          const row            = document.createElement('tr')
          const amenity        = document.createElement('td')
          const amenity_type   = document.createElement('td')
          const unit           = document.createElement('td')
          const stops_count    = document.createElement('td')
          const total_downtime = document.createElement('td')

          if (line.amenity_id)
          {
            amenity.innerHTML        = `<a href="/amenities/${line.amenity_id}?detail=machine_stop" target="_blank">${line.amenity}</a>`
            amenity_type.innerHTML   = line.amenity_type
          }
          unit.innerHTML           = `<a href="/units/${line.unit_id}" target="_blank">${line.unit}</a>`
          stops_count.innerHTML    = line.stops_count
          total_downtime.innerHTML = line.total_downtime

          row.appendChild(amenity)
          row.appendChild(amenity_type)
          row.appendChild(unit)
          row.appendChild(stops_count)
          row.appendChild(total_downtime)

          elem.appendChild(row)
        })
      })
  }

  status_pie(elem) {
    const for_self = this.daterangeTarget.dataset.forSelf
    fetch(`/companies/stat_graph_data?graph=${elem.dataset.graph}&for_self=${for_self}`,
      {
        method:  'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        elem.innerHTML = ''
        let canvas = document.createElement('canvas')
        canvas.classList.add('chart')
        elem.appendChild(canvas)
        const counter = {
          id: 'counter',
          beforeDraw(chart, args, options) {
            const { ctx, chartArea: { top, right, bottom, left, width, height} } = chart
            ctx.save()

            ctx.beginPath()
            ctx.arc(75, 75, 30, 0, 2 * Math.PI)
            ctx.fillStyle = '#CC82E5'
            ctx.fill()
            ctx.font = options.fontSize + ' ' + options.fontFamily
            ctx.textAlign = 'center'
            ctx.fillStyle = options.fontColor
            ctx.fillText(data.total, width / 2, top + (height / 1.85))
          }
        }
        let chart = new Chart(canvas, {
          type:    'doughnut',
          data,
          plugins: [counter],
          options: {
            onHover: (event, chartElement) => {
              event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
            },
            responsive: true,
            parsing:    {
              key: 'count'
            },
            plugins: {
              legend: { display: false },
              labels: {
              // render 'label', 'value', 'percentage', 'image' or custom function, default is 'percentage'
                render: 'value'
              },
              tooltip: {
                enabled: true
              },
              counter: {
                fontColor:  'white',
                fontSize:   '1rem',
                fontFamily: 'sans-serif'
              }
            },
          },
        })

        canvas.onclick = function(evt) {
          var activePoints = chart.getElementsAtEventForMode(evt, 'nearest', { intersect: true }, true)
          if (activePoints.length) {
            var firstPoint = activePoints[0]
            var label = chart.data.labels[firstPoint.index]
            var value = chart.data.datasets[firstPoint.datasetIndex].data[firstPoint.index]
            window.location.href = value.link
          }
        }
      })
  }
}
