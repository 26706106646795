import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  static targets = ['totalQuantity']

  updateTotal(e) {
    const difference      = e.currentTarget.value
    const currentQuantity = this.totalQuantityTarget.dataset.currentQuantity

    this.totalQuantityTarget.innerText = parseInt(currentQuantity) + parseInt(difference)
  }

}
